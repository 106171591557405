<template>
  <transition appear name="fade">
    <div class="container p-0">
      <appHeader></appHeader>
      <appAdminSidebar></appAdminSidebar>
      <div v-if="isSettingsLoading" class="text-center m-4 bold">
        loading...
      </div>
      <div v-if="isSettingsLoadError" class="text-center m-4 bold">
        Could not load settings
      </div>
      <div v-if="isSettingsLoaded" class="card-container">
        <form v-on:submit.prevent="" class="row px-4 align-bottom">
          <div class="my-1 col-lg col-sm-4">
            <label>Number of days before new cards expire</label>
            <b-form-input v-model.number="currentExpirationDays" class="form-control" min="0" required type="number"></b-form-input>
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="my-1 col-lg col-sm-4">
            <label>Threshold for minimum fund in Tango before a warning email is sent</label>
            <b-form-input v-model.number="currentTangoFundsThreshold" class="form-control" min="0" required type="number"></b-form-input>
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="my-1 col-lg col-sm-4">
            <label>Which email to warn when Tango fund fall below the Threshold</label>
            <b-form-input v-model="currentTangoFundsEmail" class="form-control" min="0" required type="email"></b-form-input>
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="text-right my-2 col-lg-12 d-block">
            <button class="btn btn-primary btn-md btn-sm-block" v-on:click="putSettings">
              Update
            </button>
          </div>
        </form>
      </div>
      <appFooter></appFooter>
    </div>
  </transition>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AdminSidebar from '../AdminSidebar.vue'
import { mapState } from 'vuex'

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    appAdminSidebar: AdminSidebar
  },
  data () {
    return {
      newExpirationDays: null,
      newTangoFundsEmail: null,
      newTangoFundsThreshold: null,
    }
  },
  computed: {
    ...mapState({
      expirationDays: state => state.admin.settings.expirationDays,
      tangoFundsEmail: state => state.admin.settings.tangoFundsEmail,
      tangoFundsThreshold: state => state.admin.settings.tangoFundsThreshold,

      isSettingsLoaded: state => state.admin.status.settings.initialized,
      isSettingsLoading: state => state.admin.status.settings.refreshing,
      isSettingsLoadError: state => state.admin.status.settings.error,
    }),
    currentExpirationDays: {
      get () {
        return (this.newExpirationDays === null) ? this.expirationDays : this.newExpirationDays
      },
      set (val) {
        this.newExpirationDays = val
      }
    },
    currentTangoFundsEmail: {
      get () {
        return (this.newTangoFundsEmail === null) ? this.tangoFundsEmail : this.newTangoFundsEmail
      },
      set (val) {
        this.newTangoFundsEmail = val
      }
    },
    currentTangoFundsThreshold: {
      get () {
        return (this.newTangoFundsThreshold === null) ? this.tangoFundsThreshold : this.newTangoFundsThreshold
      },
      set (val) {
        this.newTangoFundsThreshold = val
      }
    },
  },
  methods: {
    putSettings: function () {
      this.$store
          .dispatch('admin/putSettings', {
            expirationDays: this.currentExpirationDays,
            tangoFundsEmail: this.currentTangoFundsEmail,
            tangoFundsThreshold: this.currentTangoFundsThreshold,
          })
          .then(() => {
                this.showSuccess('Settings updated')
              },
              (err) => {
                this.showError('Failed to update settings')
              })
          .catch(() => {
            this.showError('Failed to update settings')
          })
    },
    showSuccess: function (message, onClose) {
      this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
          .then(() => {
            onClose()
          })
    },
    showError: function (message) {
      this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okVariant: 'danger',
        centered: true
      })
    }
  },
  created () {
    this.$store.dispatch('admin/fetchSettings')
  }
}
</script>

<style lang="scss">
label{
  min-height:45px;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { staticClass: "container p-0" },
      [
        _c("appHeader"),
        _c("appAdminSidebar"),
        _vm.isSettingsLoading
          ? _c("div", { staticClass: "text-center m-4 bold" }, [
              _vm._v(" loading... ")
            ])
          : _vm._e(),
        _vm.isSettingsLoadError
          ? _c("div", { staticClass: "text-center m-4 bold" }, [
              _vm._v(" Could not load settings ")
            ])
          : _vm._e(),
        _vm.isSettingsLoaded
          ? _c("div", { staticClass: "card-container" }, [
              _c(
                "form",
                {
                  staticClass: "row px-4 align-bottom",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "my-1 col-lg col-sm-4" },
                    [
                      _c("label", [
                        _vm._v("Number of days before new cards expire")
                      ]),
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: { min: "0", required: "", type: "number" },
                        model: {
                          value: _vm.currentExpirationDays,
                          callback: function($$v) {
                            _vm.currentExpirationDays = _vm._n($$v)
                          },
                          expression: "currentExpirationDays"
                        }
                      }),
                      _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("Required")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "my-1 col-lg col-sm-4" },
                    [
                      _c("label", [
                        _vm._v(
                          "Threshold for minimum fund in Tango before a warning email is sent"
                        )
                      ]),
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: { min: "0", required: "", type: "number" },
                        model: {
                          value: _vm.currentTangoFundsThreshold,
                          callback: function($$v) {
                            _vm.currentTangoFundsThreshold = _vm._n($$v)
                          },
                          expression: "currentTangoFundsThreshold"
                        }
                      }),
                      _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("Required")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "my-1 col-lg col-sm-4" },
                    [
                      _c("label", [
                        _vm._v(
                          "Which email to warn when Tango fund fall below the Threshold"
                        )
                      ]),
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: { min: "0", required: "", type: "email" },
                        model: {
                          value: _vm.currentTangoFundsEmail,
                          callback: function($$v) {
                            _vm.currentTangoFundsEmail = $$v
                          },
                          expression: "currentTangoFundsEmail"
                        }
                      }),
                      _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("Required")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right my-2 col-lg-12 d-block" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-md btn-sm-block",
                          on: { click: _vm.putSettings }
                        },
                        [_vm._v(" Update ")]
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e(),
        _c("appFooter")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }